import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "open-the-staff-dashboard"
    }}>{`Open the Staff Dashboard`}</h1>
    <hr></hr>
    <p>{`To open and use the Staff Dashboard, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Channels section from the left side menu options. `}</li>
      <li parentName="ol">{`Select the Channel you want to manage the pickup requests from. `}</li>
      <li parentName="ol">{`Within the selected Channel, click on the 'Collection / Pickup' tab.`}</li>
      <li parentName="ol">{`Select 'Open Staff Dashboard'. This action will load the Staff Dashboard in the selected tab. `}</li>
    </ol>
    <p><img alt="CMS Open Staff Dashboard" src={require("./images/open_staff_dashboard.png")} />{` `}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`Once opened, the Click & Collect Staff Dashboard will load. The En Route, Ready, and Arrived columns will separate out the requests into the correct status:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`A session will start at and appear within the En Route column when a patron submits their information on the Prepare Screen within your app. This column indicates the numnber of patrons that are currently coming to the library to pick up their items. `}</li>
          <li parentName="ul">{`A session will move to the Ready column when a staff member has gathered the item(s) the patron is picking up and clicks the 'Items Pulled Button'. `}</li>
          <li parentName="ul">{`A session will move to the Arrived column when a patron announces their arrival to the location for pickup. `}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the upper right corner of the Staff Dashboard, there is a 'Disable/Enable' toggle button:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Disabled means that patrons can't submit a request to pickup their items. `}</li>
          <li parentName="ul">{`Enabled sets Click & Collect to active and allows patrons to submit requests for pickup. `}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Also in the upper right corner, there is a hamburger menu option that houses the controls for the Staff Dashboard:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Location specifies which library dashboard is being managed. The locations listed here will be based on the channels you have permissions for. `}</li>
          <li parentName="ul">{`Max Queue details the number of sessions allowed in Click & Collect. In the event a patron attempts to initate a pickup request that exceeds the Max Queue set, your app will display a busy screen to the patron's device. `}</li>
          <li parentName="ul">{`View Forthcoming Sessions displays the patron's who have started the pickup request process but have not indicated that they are on their way to the library. While `}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      